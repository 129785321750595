var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "page-topbar" } }, [
    _c("div", { staticClass: "navbar-header" }, [
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "navbar-brand-box" },
          [
            _c(
              "router-link",
              { staticClass: "logo logo-dark", attrs: { to: "/" } },
              [
                _c("span", { staticClass: "logo-sm" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/logo-sm.png"),
                      alt: "",
                      height: "28"
                    }
                  })
                ]),
                _c("span", { staticClass: "logo-lg" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/logo-dark.png"),
                      alt: "",
                      width: "180"
                    }
                  })
                ])
              ]
            ),
            _c(
              "router-link",
              { staticClass: "logo logo-light", attrs: { to: "/" } },
              [
                _c("span", { staticClass: "logo-sm" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/logo-sm.png"),
                      alt: "",
                      height: "28"
                    }
                  })
                ]),
                _c("span", { staticClass: "logo-lg" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/logo-light.png"),
                      alt: "",
                      width: "180"
                    }
                  })
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass:
              "btn btn-sm px-3 font-size-16 header-item vertical-menu-btn",
            attrs: { type: "button", id: "vertical-menu-btn" },
            on: { click: _vm.toggleMenu }
          },
          [_c("i", { staticClass: "fa fa-fw fa-bars" })]
        )
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "d-inline-block d-lg-none ms-2",
              attrs: {
                variant: "white",
                "toggle-class": "header-item noti-icon",
                right: "",
                "menu-class": "dropdown-menu-lg p-0 dropdown-menu-end"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [_c("i", { staticClass: "uil-search" })]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("form", { staticClass: "p-3" }, [
                _c("div", { staticClass: "form-group m-0" }, [
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Search...",
                        "aria-label": "Recipient's username"
                      }
                    }),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_c("i", { staticClass: "mdi mdi-magnify" })]
                      )
                    ])
                  ])
                ])
              ])
            ]
          ),
          _c("div", { staticClass: "dropdown d-none d-lg-inline-block ms-1" }, [
            _c(
              "button",
              {
                staticClass: "btn header-item noti-icon waves-effect",
                attrs: {
                  type: "button",
                  "data-toggle": "fullscreen",
                  title: "Exibir em tela cheia"
                },
                on: { click: _vm.initFullScreen }
              },
              [_c("i", { staticClass: "uil-minus-path" })]
            )
          ]),
          _c(
            "b-dropdown",
            {
              staticClass: "d-inline-block",
              attrs: {
                "toggle-class": "header-item",
                right: "",
                variant: "white",
                "menu-class": "dropdown-menu-end"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("img", {
                        staticClass: "rounded-circle header-profile-user",
                        attrs: {
                          src: require("@/assets/images/avatar.png"),
                          alt: "Header Avatar"
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-none d-xl-inline-block ms-1 fw-medium font-size-15"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$store.state.authfack.user &&
                                  _vm.$store.state.authfack.user.nome
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("i", {
                        staticClass:
                          "uil-angle-down d-none d-xl-inline-block font-size-15"
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "dropdown-item",
                  attrs: { to: { name: "sistema.alterar-senha" } }
                },
                [
                  _c("i", {
                    staticClass:
                      "uil uil-key-skeleton-alt font-size-18 align-middle me-1 text-muted"
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Alterar senha")
                  ])
                ]
              ),
              _c(
                "a",
                { staticClass: "dropdown-item", attrs: { href: "/logout" } },
                [
                  _c("i", {
                    staticClass:
                      "uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
                  }),
                  _c("span", { staticClass: "align-middle" }, [_vm._v("SAIR ")])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }