var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-menu" },
    [
      _c(
        "div",
        { staticClass: "navbar-brand-box" },
        [
          _c(
            "router-link",
            { staticClass: "logo logo-dark", attrs: { to: "/" } },
            [
              _c("span", { staticClass: "logo-sm" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/logo-sm.png"),
                    alt: "",
                    height: "28"
                  }
                })
              ]),
              _c("span", { staticClass: "logo-lg" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/logo-dark.png"),
                    alt: "",
                    width: "180"
                  }
                })
              ])
            ]
          ),
          _c(
            "router-link",
            { staticClass: "logo logo-light", attrs: { to: "/" } },
            [
              _c("span", { staticClass: "logo-sm" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/logo-sm.png"),
                    alt: "",
                    height: "28"
                  }
                })
              ]),
              _c("span", { staticClass: "logo-lg" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/logo-light.png"),
                    alt: "",
                    width: "180"
                  }
                })
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass:
            "btn btn-sm px-3 font-size-16 header-item vertical-menu-btn",
          attrs: { type: "button" },
          on: { click: _vm.toggleMenu }
        },
        [_c("i", { staticClass: "fa fa-fw fa-bars" })]
      ),
      _vm.menuItems.length > 0
        ? _c(
            "simplebar",
            { ref: "currentMenu", staticClass: "sidebar-menu-scroll" },
            [
              _c("div", { attrs: { id: "sidebar-menu" } }, [
                _c(
                  "ul",
                  {
                    staticClass: "metismenu list-unstyled",
                    attrs: { id: "side-menu" }
                  },
                  [
                    _vm._l(_vm.menuItems, function(item) {
                      return [
                        item.isTitle
                          ? _c(
                              "li",
                              { key: item.id, staticClass: "menu-title" },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          : _vm._e(),
                        !item.isTitle && !item.isLayout
                          ? _c(
                              "li",
                              { key: item.id },
                              [
                                _vm.hasItems(item)
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "is-parent",
                                        class: {
                                          "has-arrow": !item.badge,
                                          "has-dropdown": item.badge
                                        },
                                        attrs: { href: "javascript:void(0);" }
                                      },
                                      [
                                        item.icon
                                          ? _c("i", { class: "" + item.icon })
                                          : _vm._e(),
                                        item.badge
                                          ? _c(
                                              "span",
                                              {
                                                class:
                                                  "badge rounded-pill bg-" +
                                                  item.badge.variant +
                                                  " float-end"
                                              },
                                              [_vm._v(_vm._s(item.badge.text))]
                                            )
                                          : _vm._e(),
                                        _c("span", [_vm._v(_vm._s(item.label))])
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.hasItems(item)
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "side-nav-link-ref",
                                        attrs: {
                                          to: item.link,
                                          "data-menu": item.menu
                                        }
                                      },
                                      [
                                        item.icon
                                          ? _c("i", { class: "" + item.icon })
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(_vm._s(item.label))
                                        ]),
                                        item.badge
                                          ? _c(
                                              "span",
                                              {
                                                class:
                                                  "badge rounded-pill bg-" +
                                                  item.badge.variant +
                                                  " float-end"
                                              },
                                              [_vm._v(_vm._s(item.badge.text))]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.hasItems(item)
                                  ? _c(
                                      "ul",
                                      {
                                        staticClass: "sub-menu",
                                        attrs: { "aria-expanded": "false" }
                                      },
                                      _vm._l(item.subItems, function(
                                        subitem,
                                        index
                                      ) {
                                        return _c(
                                          "li",
                                          { key: index },
                                          [
                                            !_vm.hasItems(subitem)
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "side-nav-link-ref",
                                                    attrs: {
                                                      to: subitem.link,
                                                      "data-menu": subitem.menu
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(subitem.label)
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.hasItems(subitem)
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "side-nav-link-a-ref has-arrow",
                                                    attrs: {
                                                      href:
                                                        "javascript:void(0);"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(subitem.label)
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.hasItems(subitem)
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "sub-menu mm-collapse",
                                                    attrs: {
                                                      "aria-expanded": "false"
                                                    }
                                                  },
                                                  _vm._l(
                                                    subitem.subItems,
                                                    function(
                                                      subSubitem,
                                                      index
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              staticClass:
                                                                "side-nav-link-ref",
                                                              attrs: {
                                                                to:
                                                                  subSubitem.link,
                                                                "data-menu":
                                                                  subSubitem.menu
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  subSubitem.label
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }