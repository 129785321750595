<script>
import appConfig from "@/app.config";
import { getAll as getConfig } from "@/services/configuracao.service"

export default {
  data() {
    return {
      appConfig,
      devConfig: null,
    };
  },
  mounted() {
    getConfig().then(response => {
      this.devConfig = response;
    });
  },
}
</script>

<template>
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-6 text-center text-md-start">
                {{ appConfig.devYear }} ® {{ appConfig.title }} {{ devConfig ? ` - Versão ${devConfig.versao}` : "" }}
            </div>
            <div v-if="devConfig" class="col-12 col-sm-6 text-center text-md-end">
                Desenvolvido por <a :href="devConfig.devUrl" target="_blank">{{ devConfig.devNome }}</a>
            </div>
        </div>
    </div>
</footer>
</template>