export const mountMenuItems = () => {
  var menu = [
    {
      id: 1,
      label: "Menu Principal",
      isTitle: true,
    },
    {
      id: 2,
      label: "Home",
      icon: "uil-home-alt",
  
      link: "/",
      menu: "home",
    },
  ];

  let user = JSON.parse(localStorage.getItem("user"));

  var roles = user.modulosUsuarioStr.split(',');

  if (roles.includes('lotes')) {
    let menuLotes = {
      id: 701,
      label: "Lotes de faturamento",
      icon: "mdi mdi-clipboard-check-multiple-outline",
      menu: "lotes",
      subItems: []
    }
    if (roles.includes('lotesScLotes')) {
      menuLotes.subItems.push({
        id: 702,
        label: "Lotes de faturamento",
        link: "/faturamento/lotes",
        parentId: 701,
        menu: "faturamento.lotes",
      });
    }
    if (roles.includes('lotesUpload')) {
      menuLotes.subItems.push({
        id: 703,
        label: "Upload lotes",
        link: "/faturamento/lote/upload",
        parentId: 701,
        menu: "faturamento.upload",
      });
    }
    menu.push(menuLotes);
  }

  if (roles.includes('relatorios')) {
    let menuRelatorios = {
      id: 801,
      label: "Relatórios",
      icon: "uil uil-graph-bar",
      menu: "relatorios",
      subItems: []
    }
    if (roles.includes('relAuditoriaRetrospectiva')) {
      menuRelatorios.subItems.push({
        id: 802,
        label: "Auditoria Retrospectiva",
        link: "/relatorio/auditoria-retrospectiva",
        parentId: 801,
        menu: "relatorios.auditoria-retrospectiva",
      });
    }
    if (roles.includes('relFaturamento')) {
      menuRelatorios.subItems.push({
        id: 804,
        label: "Faturamento",
        link: "/relatorio/faturamento",
        parentId: 801,
        menu: "relatorios.faturamento",
      });
    }
    if (roles.includes('relRetrospectivaContasMedicas')) {
      menuRelatorios.subItems.push({
        id: 803,
        label: "Retrospectiva (Contas médicas)",
        link: "/relatorio/retrospectiva-contas",
        parentId: 801,
        menu: "relatorios.retrospectiva-contas",
      });
    }
    if (roles.includes('relGlosas')) {
      menuRelatorios.subItems.push({
        id: 805,
        label: "Glosas",
        link: "/relatorio/glosas",
        parentId: 801,
        menu: "relatorios.glosas",
      });
    }
    // next: 806
    menu.push(menuRelatorios);
  }

  if (roles.includes('adm')) {
    let menuAdm = 
      {
        id: 601,
        label: "Adm. do Sistema",
        icon: "mdi mdi-security",
        menu: "adm",
        subItems: []
      };
    
    if (roles.includes('admPerfil')) {
      menuAdm.subItems.push({
        id: 602,
        label: "Perfis",
        link: "/sistema/perfis",
        parentId: 601,
        menu: "sistema.perfil",
      });
    }
    if (roles.includes('admUsu')) {
      menuAdm.subItems.push({
        id: 605,
        label: "Usuários",
        link: "/sistema/usuarios",
        parentId: 601,
        menu: "sistema.usuario",
      });
    }
    if (roles.includes('admConv')) {
      menuAdm.subItems.push({
        id: 603,
        label: "Convênios",
        link: "/sistema/convenios",
        parentId: 601,
        menu: "sistema.convenio",
      });
    }
    if (roles.includes('admHosp')) {
      menuAdm.subItems.push({
        id: 604,
        label: "Hospitais",
        link: "/sistema/hospitais",
        parentId: 601,
        menu: "sistema.hospital",
      });
    }
    menu.push(menuAdm);
  }

  if (roles.includes('cad')) {
    let menuCad = {
      id: 606,
      label: "Cadastros",
      icon: "mdi mdi-cog-outline",
      menu: "cadastros",
      subItems: []
    }
    if (roles.includes('cadMotGlosa')) {
      menuCad.subItems.push({
        id: 6061,
        label: "Motivo Glosa",
        link: "/cadastros/motivos-glosa",
        parentId: 606,
        menu: "cadastros.motivoglosa",
      });
    }
    if (roles.includes('cadMotPendencia')) {
      menuCad.subItems.push({
        id: 6062,
        label: "Motivos Pendência de Auditoria",
        link: "/cadastros/motivos-pendencia",
        parentId: 606,
        menu: "cadastros.motivospendenciaauditoria",
      });
    }
    if (roles.includes('cadTabelaDominio')) {
      menuCad.subItems.push({
        id: 6063,
        label: "Tabelas",
        link: "/cadastros/tabelas",
        parentId: 606,
        menu: "cadastros.tabelatuss",
      });
    }
    if (roles.includes('cadProcedimento')) {
      menuCad.subItems.push({
        id: 6063,
        label: "Procedimentos",
        link: "/cadastros/procedimentos",
        parentId: 606,
        menu: "cadastros.procedimento",
      });
    }
    menu.push(menuCad);
  }

  return menu;
}
