var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-6 text-center text-md-start" },
          [
            _vm._v(
              " " +
                _vm._s(_vm.appConfig.devYear) +
                " ® " +
                _vm._s(_vm.appConfig.title) +
                " " +
                _vm._s(
                  _vm.devConfig ? " - Versão " + _vm.devConfig.versao : ""
                ) +
                " "
            )
          ]
        ),
        _vm.devConfig
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 text-center text-md-end" },
              [
                _vm._v(" Desenvolvido por "),
                _c(
                  "a",
                  { attrs: { href: _vm.devConfig.devUrl, target: "_blank" } },
                  [_vm._v(_vm._s(_vm.devConfig.devNome))]
                )
              ]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }